.wrapper {
    position: relative;
    width: 400px;
    height: 550px;
    background-color: white;
    border-radius: 20px;
    color: black;
    display: flex;
    align-items: center;
    overflow: hidden;
    transition: height 0.2s ease;
}

.uk {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 18px;
}

.fb-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    padding-top: 10px;
}

.forgot {
    text-decoration: none;
    color: black;
    align-items: center;
    display: flex;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 60px;
}


.meta {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 10px;
    font-weight: 700;
    color: grey;
    margin-right: 5px;
}

.footer a{
    text-decoration: none;
    color: black;
    padding-top: 20px;
    margin-right: 5px;
    font-size: 10px;
}

.wrapper.active {
    height: 600px;
}

.wrapper .form-box {
    width: 100%;
    padding: 40px;
}

.wrapper .form-box.login {
    transition: transform 0.18s ease;
    transform: translateX(0);
}

.wrapper.active .form-box.login {
    transition: none;
    transform: translateX(-400px);
}

.wrapper .form-box.register {
    position: absolute;
    transition: none;
    transform: translateX(400px);
}

.wrapper.active .form-box.register {
    transition: transform 0.18s ease;
    transform: translateX(0);
}

form h1 {
    font-size: 36px;
    text-align: center;
}

form .input-box {
    position: relative;
    width: 100%;
    height: 50px;
    margin: 30px 0;
}

form .password-box {
    position: relative;
    width: 100%;
    height: 50px;
    margin: 30px 0 5px;
}

.input-box input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: 2px solid grey;
    outline: none;
    border-radius: 10px;
    font-size: 16px;
    color: black;
    padding: 20px 45px 20px 20px;
}

.input-box.active {
    border: 1px solid #35035A;
}

.coupon-box input {
    margin: 20px 0;
}

.password-box input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    border-radius: 40px;
    border: 2px solid black;
    font-size: 16px;
    color: black;
    padding: 20px 45px 20px 20px;
    margin-bottom: 5px;
}

.password-box .icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
}

.input-box .icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
}

form .remember-forgot {
    display: flex;
    justify-content: space-between;
    font-size: 14.5px;
    margin: -15px 0 15px;
}

.remember-forgot label input {
    accent-color: black;
    margin-right: 4px;
}

.remember-forgot a {
    color: black;
    text-decoration: none;
}

.remember-forgot a:hover {
    text-decoration: underline;
}

form .first {
    width: 100%;
    height: 45px;
    background: #2F75EE;
    border: none;
    outline: none;
    border-radius: 40px;
    cursor: pointer;
    font-size: 16px;
    color: white;
    font-weight: 700;
}

form .second {
    width: 100%;
    height: 45px;
    background: white;
    border: 2px solid  #2F75EE;
    outline: none;
    border-radius: 40px;
    cursor: pointer;
    font-size: 16px;
    color:  #2F75EE;
    font-weight: 700;
}


form .register-link {
    font-size: 14.5px;
    text-align: center;
    margin: 20px 0 15px;
}

.register-link p a {
    color: black;
    text-decoration: none;
    font-weight: 600;
}

.register-link p a:hover {
    text-decoration: underline;
}

form .login-link {
    font-size: 14.5px;
    text-align: center;
    margin: 20px 0 15px;
}

.login-link p a {
    color: black;
    text-decoration: none;
    font-weight: 600;
}

.login-link p a:hover {
    text-decoration: underline;
}

@media only screen and (max-width: 600px) {
    .wrapper {
        width: 300px;
        height: 500px;
    }

    .wrapper .form-box {
        padding: 20px;
    }

    form h1 {
        font-size: 28px;
    }

    form .input-box, form .password-box {
        height: 40px;
        margin: 20px 0;
    }

    .input-box input, .password-box input {
        padding: 10px 35px 10px 15px;
    }

    .input-box .icon, .password-box .icon {
        font-size: 14px;
    }

    form button {
        height: 40px;
        font-size: 14px;
    }

    form .register-link, form .login-link {
        font-size: 12px;
        margin: 15px 0 10px;
    }

    .remember-forgot {
        font-size: 12px;
    }
}

@media only screen and (min-width: 768px) {
    .wrapper {
        width: 400px;
        height: 600px;
    }

    body {
        font-family: "Poppins", sans-serif;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        background: white;
    }
}
